import React, { useEffect, useState, useRef } from "react";
import Logo from "../media/wodo-logo.png";
import Menu from "../media/wodo-hamburger-icon.png";
import Close from "../media/wodo-menu-close-icon.png";
import { Link, NavLink, useLocation } from "react-router-dom";

function Header() {
  const location = useLocation();
  const [menuStatus, setMenuStatus] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [serviceActive, setServiceActive] = useState(false);

  // Reference for the dropdown menu
  const dropdownRef = useRef(null);

  const handleButtonClick = () => {
    setMenuStatus((prev) => !prev);
  };

  useEffect(() => {
    const dropdownPaths = [
      "design-and-branding/",
      "development/",
      "growth-and-performance-marketing/",
    ];
    const path = location.pathname.split("/").pop();

    if (dropdownPaths.includes(path)) {
      setServiceActive(true);
    } else {
      setServiceActive(false);
    }
    setMenuStatus(false);
  }, [location]);

  useEffect(() => {
    let lastScrollTop = 0;

    const handleScroll = () => {
      const header = document.querySelector(".header_container");
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;

      if (scrollTop > lastScrollTop) {
        header.classList.remove("scrolled");
        header.style.top = "-100px"; // Hide the header
      } else {
        header.classList.add("scrolled");
        header.style.top = "0"; // Show the header
      }
      lastScrollTop = scrollTop;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleDropdownClick = () => {
    setDropdownOpen((prev) => !prev);
  };

  const handleDropdownItemClick = (to, e) => {
    e.preventDefault();
    setDropdownOpen(false);
    setMenuStatus(false);

    setTimeout(() => {
      window.location.href = to;
    }, 150);
  };



  // Close dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="header_container">
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img
              src={Logo}
              className="header_logo"
              alt="Best Digital Marketing Agency in Bangalore"
              title="Digital Marketing Agency For Startups"
            />
          </Link>
          <button
            className={`navbar-toggler ${menuStatus ? "active" : ""}`}
            type="button"
            onClick={handleButtonClick}
            aria-controls="navbarNav"
            aria-expanded={menuStatus ? "true" : "false"}
            aria-label="Toggle navigation"
          >
            <img src={menuStatus ? Close : Menu} alt="Menu" />{" "}
          </button>

          {/* Menu Items */}
          <div
            className={`collapse navbar-collapse ${menuStatus ? "show" : ""}`}
            id="navbarNav"
          >
            <ul className="navbar-nav ml-5">
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/"
                  exact
                  activeClassName="active"
                >
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/projects/"
                  activeClassName="active"
                >
                  Projects
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/about-us/"
                  activeClassName="active"
                >
                  Our Story
                </NavLink>
              </li>
              <li
                className={`nav-item dropdown ${dropdownOpen ? "active" : ""}`}
                ref={dropdownRef}
              >
                <button
                  className={
                    serviceActive
                      ? "nav-link dropdown-toggle active"
                      : "nav-link dropdown-toggle"
                  }
                  type="button"
                  onClick={handleDropdownClick}
                  aria-expanded={dropdownOpen ? "true" : "false"}
                >
                  Services
                </button>
                <ul className={`dropdown-menu ${dropdownOpen ? "show" : ""}`}>
                  <li>
                    <a
                      className="dropdown-item"
                      href="/design-and-branding-services/"
                      onClick={(e) =>
                        handleDropdownItemClick(
                          "/design-and-branding-services/",
                          e
                        )
                      }
                    >
                      Design & Branding
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="/custom-web-development-services/"
                      onClick={(e) =>
                        handleDropdownItemClick(
                          "/custom-web-development-services",
                          e
                        )
                      }
                    >
                      Development
                    </a>
                  </li>
                  <li>
                    <a
                      className="dropdown-item"
                      href="/performance-marketing-services/"
                      onClick={(e) =>
                        handleDropdownItemClick(
                          "/performance-marketing-services/",
                          e
                        )
                      }
                    >
                      Growth & Performance Marketing
                    </a>
                  </li>
                  {/* <li>
                    <a
                      className="dropdown-item"
                      href="/seo-services/"
                      onClick={(e) =>
                        handleDropdownItemClick("/seo-services/", e)
                      }
                    >
                      Bing SEO
                    </a>
                  </li> */}
                </ul>
              </li>
              <li className="nav-item">
                <a href="https://wodo.digital/insights/"  rel="noopener noreferrer" className="nav-link">Insights</a>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/contact-us/"
                  activeClassName="active"
                >
                  Contact
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Header;
