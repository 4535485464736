import React, { useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import "../../css/App.css";
import "../../css/index.css";
import { Helmet } from "react-helmet-async";
import HeroSection from "./HeroSection/HeroSection";
import ProjectTabs from "./ProjectTabs/ProjectTabs";
import Services from "./Services/Services";
import Awards from "./Awards/Awards";
import Clients from "./Clients/Clients";
import FloatingButton from "../../components/FloatingButton/FloatingButton";
import CaseStudies from "./CaseStudies/CaseStudies";
import Cta from "./cta/Cta.js";
import OurServices from "./OurServices/OurServices.js";
import Faqs from "./Faq's/Faqs.js";


const Home = () => {
  // const currentUrl = window.location.href;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Digital Marketing, Web Design & Development Services | Wodo Digital
        </title>
        <meta
          name="description"
          content="Wodo Digital, a leading provider of Digital Marketing, Web Design & Development Services, specializes in custom solutions tailored to your business needs."
        />
        <meta
          name="keywords"
          content="Digital Marketing Services, Web Design and Development, Custom Website Design, E-commerce Development, SEO Services, PPC Campaigns, Social Media Marketing, Custom Web Development, UX/UI Design.​"
        />
        <meta
          property="og:title"
          content="Digital Marketing, Web Design & Development Services | Wodo Digital"
        ></meta>

        <meta
          property="og:description"
          content="Drive growth and elevate your online presence with our expert solutions in digital marketing, web design, and web development."
        ></meta>
        <meta
          property="og:image"
          content="https://wodo.digital/media/home/featured-image.webp"
        />
        <meta
          property="og:image:alt"
          content="Digital Marketing, Web Design & Development Services at Wodo Digital"
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="628" />
        <meta property="og:url" content="https://wodo.digital/" />
        <link rel="canonical" href="https://wodo.digital/" />

        <meta name="publisher" content="WODO" />
        <meta name="author" content="WODO" />
        <meta name="copyright" content="WODO" />
        <link
          rel="publisher"
          href="https://in.linkedin.com/company/wodo-digital"
        />

        {/* <link rel="canonical" href={currentUrl} /> */}

        {/* Organization Schema */}
        <script type="application/ld+json">
          {`
            { 

            "@context": "https://schema.org", 

            "@type": "Organization", 

            "name": "Wodo", 

            "url": "https://wodo.digital", 

            "logo": "https://wodo.digital/static/media/wodo-logo.1e15894e3c85bd916a05.png", 

            "contactPoint": { 

                "@type": "ContactPoint", 

                "email": "hello.wododigital@gmail.com", 

                "telephone": "+919482290970", 

                "contactType": "Customer Service" 

            }, 

            "address": { 

                "@type": "PostalAddress", 

                "streetAddress": "1st floor, Laksmi Arcade, 1, 1st Main Rd, BDA Layout, Marilingappa Extension, Annapurneshwari Nagar", 

                "addressLocality": "Bengaluru", 

                "addressRegion": "Karnataka", 

                "postalCode": "560091", 

                "addressCountry": "IN" 

            }, 

            "sameAs": [ 

                "https://in.linkedin.com/company/wodo-digital", 

                "https://www.instagram.com/wodo.digital/" 

            ] 

            } 
        
        
        `}
        </script>

        {/* Breadcrumb Schema */}

        <script type="application/ld+json">
          {`

            { 

            "@context": "https://schema.org", 

            "@type": "BreadcrumbList", 

            "itemListElement": [{ 

                "@type": "ListItem", 

                "position": 1, 

                "name": "Home", 

                "item": "https://wodo.digital" 

            }] 

            }   
            `}
        </script>

        {/* Website Schema  */}
        <script type="application/ld+json">
          {`
        { 

  "@context": "https://schema.org", 

  "@type": "WebSite", 

  "name": "Wodo Digital", 

  "url": "https://wodo.digital", 

  "potentialAction": { 

    "@type": "SearchAction", 

    "target": "https://wodo.digital/?s={search_term_string}", 

    "query-input": "required name=search_term_string" 

  } 

} 
        
        `}
        </script>

        {/* Local Schema */}
        <script type="application/ld+json">
          {`
             { 

                "@context": "https://schema.org", 

                "@type": "LocalBusiness", 

                "name": "Wodo", 

                "image": "https://wodo.digital/static/media/wodo-logo.1e15894e3c85bd916a05.png", 

                "address": { 

                    "@type": "PostalAddress", 

                    "streetAddress": "1st floor, Laksmi Arcade, 1, 1st Main Rd, BDA Layout, Marilingappa Extension, Annapurneshwari Nagar", 

                    "addressLocality": "Bengaluru", 

                    "addressRegion": "Karnataka", 

                    "postalCode": "560091", 

                    "addressCountry": "IN" 

                }, 

                "telephone": "+919482290970", 

                "email": "hello.wododigital@gmail.com", 

                "url": "https://wodo.digital", 

                "openingHours": [ 

                    "Mo-Su 08:00-22:00" 

                ], 

                "sameAs": [ 

                    "https://in.linkedin.com/company/wodo-digital", 

                    "https://www.instagram.com/wodo.digital/" 

                ] 

                } 
        
        `}
        </script>

        {/* FAQ Schema */}
        <script type="application/ld+json">
          {`
            { 

            "@context": "https://schema.org", 

            "@type": "FAQPage", 

            "mainEntity": [{ 

                "@type": "Question", 

                "name": "What services does Wodo Digital offer?", 

                "acceptedAnswer": { 

                    "@type": "Answer", 

                    "text": "Wodo Digital specializes in Digital Marketing, Web Design & Development Services, including custom web design, website development, SEO, PPC, social media management, and more." 

                } 

                }, 

                { 

                "@type": "Question", 

                "name": "Why is custom web design important for my business?", 

                "acceptedAnswer": { 

                    "@type": "Answer", 

                    "text": "Custom web design ensures your website is unique, aligns with your brand, and enhances user experience, which can lead to higher engagement and conversions." 

                } 

                }, 

                { 

                "@type": "Question", 

                "name": "How does Wodo Digital approach digital marketing?", 

                "acceptedAnswer": { 

                    "@type": "Answer", 

                    "text": "We use data-driven strategies tailored to your business goals, focusing on SEO, PPC, content marketing, and social media to drive traffic and increase conversions." 

                } 

                }, 

                { 

                "@type": "Question", 

                "name": "What makes Wodo Digital different from other agencies?", 

                "acceptedAnswer": { 

                    "@type": "Answer", 

                    "text": "Wodo Digital combines creative design with technical expertise, offering personalized solutions that are innovative, scalable, and aligned with your business needs." 

                } 

                }, 

                { 

                "@type": "Question", 

                "name": "How can Wodo Digital help improve my website's performance?", 

                "acceptedAnswer": { 

                    "@type": "Answer", 

                    "text": "We enhance your website's performance by optimizing design, improving load times, and implementing effective SEO strategies to boost your online visibility and user engagement." 

                } 

                }, 

                { 

                "@type": "Question", 

                "name": "What industries does Wodo Digital serve?", 

                "acceptedAnswer": { 

                    "@type": "Answer", 

                    "text": "Wodo Digital provides services to a wide range of industries, including e-commerce, healthcare, finance, technology, and more, tailoring our approach to meet specific industry needs." 

                } 

                }, 

                { 

                "@type": "Question", 

                "name": "How can digital marketing services boost my business?", 

                "acceptedAnswer": { 

                    "@type": "Answer", 

                    "text": "Digital marketing services, such as SEO and PPC, increase your online visibility, attract more targeted traffic, and ultimately lead to higher sales and business growth." 

                } 

                }, 

                { 

                "@type": "Question", 

                "name": "What platforms and technologies does Wodo Digital use for web development?", 

                "acceptedAnswer": { 

                    "@type": "Answer", 

                    "text": "We use the latest technologies and platforms, including WordPress, Shopify, and custom development frameworks, to create secure, scalable, and user-friendly websites." 

                } 

                } 

            ] 

            } 
        
        `}
        </script>
      </Helmet>

      <FloatingButton />
      <Header />
      <HeroSection />
      <ProjectTabs />
      <OurServices />
      <Services />
      <Clients />
      <Awards />
      <CaseStudies />
      <Faqs />
      <Cta />
      <Footer />
    </>
  );
};

export default Home;
