import React from 'react';
import './TeamCard.css';
import linkedinicon from './linkedin.svg'

const TeamCard = ({ name, designation, linkedinUrl, image, instagramUrl, }) => {

  return (
    <div className="card mx-2 border-0">
      <img src={image} class="card-img-top team-image" alt="Wodo Team"></img>
      <div className="card-body team-card">
        <div className="card-content team-card-content">
          <h3 className="team-name">{name}</h3>
          <p className="team-designation">{designation}</p>
          <div className="icon-div d-flex">
            <a href={linkedinUrl} className="social-icon me-3" target="_blank" rel="noreferrer">
              <img src={linkedinicon} alt="LinkedIn icon" width="25" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamCard;